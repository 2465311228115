@import 'font/font.css';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700');
* { outline: none; -webkit-text-size-adjust:none;  }
*::-ms-clear { display: none; }
*, *:before, *:after { box-sizing: inherit; }
html, body { margin: 0; padding: 0; box-sizing: border-box; font-family: 'Spoqa Han Sans', 'NotoSansCJKkr', sans-serif; font-size: 17px; color: #333;
}
#root {
  width:100%;
}
#root > div {
  height: 100%;
}
a { text-decoration: inherit; color: inherit; }
ul { padding: 0; margin: 0; list-style: none; }
input, button, textarea, select { -webkit-appearance: none; }
input::-webkit-input-placeholder { color: #B5B5B5; }
