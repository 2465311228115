@font-face {
  font-family: GothamPro;
  src: url(GothamPro-Light.eot);
  src: url(GothamPro-Light.woff2) format("woff2"),url(GothamPro-Light.woff) format("woff"),url(GothamPro-Light.ttf) format("truetype"),url(GothamPro-Light.eot?#iefix) format("embedded-opentype");
  font-weight: 100;
  font-style: normal
}

@font-face {
  font-family: GothamPro;
  src: url(GothamPro-LightItalic.eot);
  src: url(GothamPro-LightItalic.woff2) format("woff2"),url(GothamPro-LightItalic.woff) format("woff"),url(GothamPro-LightItalic.ttf) format("truetype"),url(GothamPro-LightItalic.eot?#iefix) format("embedded-opentype");
  font-weight: 100;
  font-style: italic
}

@font-face {
  font-family: GothamPro;
  src: url(GothamPro.eot);
  src: url(GothamPro.woff2) format("woff2"),url(GothamPro.woff) format("woff"),url(GothamPro.ttf) format("truetype"),url(GothamPro.eot?#iefix) format("embedded-opentype");
  font-weight: 200;
  font-style: normal
}

@font-face {
  font-family: GothamPro;
  src: url(GothamPro-Italic.eot);
  src: url(GothamPro-Italic.woff2) format("woff2"),url(GothamPro-Italic.woff) format("woff"),url(GothamPro-Italic.ttf) format("truetype"),url(GothamPro-Italic.eot?#iefix) format("embedded-opentype");
  font-weight: 200;
  font-style: italic
}

@font-face {
  font-family: GothamPro;
  src: url(GothamPro-Medium.eot);
  src: url(GothamPro-Medium.woff2) format("woff2"),url(GothamPro-Medium.woff) format("woff"),url(GothamPro-Medium.ttf) format("truetype"),url(GothamPro-Medium.eot?#iefix) format("embedded-opentype");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: GothamPro;
  src: url(GothamPro-MediumItalic.eot);
  src: url(GothamPro-MediumItalic.woff2) format("woff2"),url(GothamPro-MediumItalic.woff) format("woff"),url(GothamPro-MediumItalic.ttf) format("truetype"),url(GothamPro-MediumItalic.eot?#iefix) format("embedded-opentype");
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: GothamPro;
  src: url(GothamPro-Bold.eot);
  src: url(GothamPro-Bold.woff2) format("woff2"),url(GothamPro-Bold.woff) format("woff"),url(GothamPro-Bold.ttf) format("truetype"),url(GothamPro-Bold.eot?#iefix) format("embedded-opentype");
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: GothamPro;
  src: url(GothamPro-BoldItalic.eot);
  src: url(GothamPro-BoldItalic.woff2) format("woff2"),url(GothamPro-BoldItalic.woff) format("woff"),url(GothamPro-BoldItalic.ttf) format("truetype"),url(GothamPro-BoldItalic.eot?#iefix) format("embedded-opentype");
  font-weight: 600;
  font-style: italic
}

@font-face {
  font-family: GothamPro;
  src: url(GothamPro-Black.eot);
  src: url(GothamPro-Black.woff2) format("woff2"),url(GothamPro-Black.woff) format("woff"),url(GothamPro-Black.ttf) format("truetype"),url(GothamPro-Black.eot?#iefix) format("embedded-opentype");
  font-weight: 800;
  font-style: normal
}

@font-face {
  font-family: GothamPro;
  src: url(GothamPro-BlackItalic.eot);
  src: url(GothamPro-BlackItalic.woff2) format("woff2"),url(GothamPro-BlackItalic.woff) format("woff"),url(GothamPro-BlackItalic.ttf) format("truetype"),url(GothamPro-BlackItalic.eot?#iefix) format("embedded-opentype");
  font-weight: 800;
  font-style: italic
}